html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

:root {
  --min-font-size: 14px;
  --max-font-size: 30px;
  --min-viewport-width: 320px;
  --max-viewport-width: 1200px;
  --color-accent: #ff00e8;
  --color-primary: #fffc3d;
  --color-black: #222;
}

html, body {
  overflow-x: hidden;
}

html {
  font-size: var(--min-font-size);
  color: var(--color-black);
  font-family: Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman, serif;
  font-weight: bold;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(1.81818vw + 8.18182px);
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: var(--max-font-size);
  }
}

body {
  background-image: url("spider-bg-1000.83d9a0ba.jpg");
  background-position: 50% -30vw;
  background-repeat: no-repeat;
  background-size: 200%;
}

@media screen and (min-width: 500px) {
  body {
    background-image: url("spider-bg-2000.f19bba66.jpg");
  }
}

@media screen and (min-width: 900px) {
  body {
    background-image: url("spider-bg-4000.af7435f3.jpg");
    background-position-y: -1000px;
    background-size: 2500px;
  }
}

img, picture {
  width: 100%;
}

h1, h2, h3, nav, button label {
  text-transform: uppercase;
  letter-spacing: .01em;
  word-spacing: .2em;
  text-align: center;
  margin-bottom: 1.5em;
  font-family: Dela Gothic One, sans-serif;
  line-height: .9em;
}

p {
  max-width: 36em;
}

.underline {
  background-size: 1px 1em;
  display: inline;
}

.underline--primary {
  box-shadow: inset 0 -.4em var(--color-primary);
}

.underline--accent {
  box-shadow: inset 0 -.4em var(--color-accent);
}

.underline--white {
  box-shadow: inset 0 -.4em #fff;
}

.anarchy {
  position: relative;
}

.anarchy:after {
  content: "";
  width: 1.5em;
  height: 1.5em;
  background-image: url("A.a85975f9.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: -12%;
  left: 63.5%;
}

.container {
  max-width: var(--max-viewport-width);
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.full-bleed {
  margin: 0 1em;
  display: block;
}

section#songs:after, section#contact:before, section#photo:before, section#photo:after, section#videos:before {
  content: "";
  height: 3em;
  width: 200%;
  min-width: 600px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -50%;
}

@media screen and (max-width: 1260px) {
  .container {
    margin: 0 1.25rem;
  }

  section#photo .container {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

section {
  padding: 3rem 0;
  position: relative;
}

section#about h1 {
  background: #fff;
}

section#about .section-intro {
  background: #fff;
  padding: 0 1em;
}

section#photo {
  z-index: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: -3rem;
  padding: 0 0 .75em;
  display: flex;
  position: relative;
}

section#photo img {
  mix-blend-mode: lighten;
  max-width: 2000px;
  transform: rotate(-2deg);
}

section#photo:before {
  background-image: url("section-edge-top-01.a5a63070.svg");
  top: -2.9rem;
}

section#photo:after {
  height: 2em;
  background-image: url("section-edge-bottom-01.1cc5da7e.svg");
  bottom: -1.9rem;
}

section#songs {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

section#songs:before {
  content: "";
  width: 240%;
  height: 100%;
  max-width: 150vw;
  min-width: 55rem;
  pointer-events: none;
  background-image: url("wires.5b602bc9.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1200px) {
  section#songs {
    padding-top: calc(12rem + 10vw);
  }
}

section#songs:after {
  background-image: url("section-edge-bottom-02.8c31f641.svg");
  bottom: -2.9rem;
}

section#contact {
  margin-top: 3rem;
}

section#contact:before {
  background-image: url("section-edge-top-01.a5a63070.svg");
  top: -2.9rem;
}

section#videos:before {
  background-image: url("section-edge-top-02.038e93e1.svg");
  top: -2.9rem;
}

section#videos {
  padding-bottom: 7rem;
}

section#videos p {
  width: 100%;
}

section#videos iframe {
  width: 100%;
  height: 50vw;
  height: 20em;
  margin-bottom: -5px;
  top: 5px;
}

.section--dark {
  background-color: var(--color-black);
  color: #fff;
}

.section--primary {
  background-color: var(--color-primary);
}

nav {
  padding: 2rem 0;
  font-size: 1.1rem;
}

nav ul {
  flex-flow: wrap;
  justify-content: center;
  margin: .5em 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

nav ul * + * {
  margin-left: 1em;
}

nav ul li {
  margin-bottom: .8em;
}

nav a {
  color: var(--color-black);
  background-color: var(--color-primary);
  box-shadow: 0px .2em var(--color-black);
  padding: .25em .5em;
  text-decoration: none;
  display: inline-block;
}

header {
  margin: 1rem 0 .5rem;
}

header picture {
  display: block;
  transform: rotate(3deg);
}

button {
  color: var(--color-black);
  cursor: pointer;
  border: .2rem solid var(--color-black);
  background: none;
  padding: .5em 1em;
}

button > * {
  color: var(--color-black);
  pointer-events: none;
}

.songs {
  grid-template-columns: 1fr;
  justify-items: center;
  gap: .75em;
  margin: 1rem 0;
  padding: 0;
  list-style: none;
  display: grid;
}

@media screen and (min-width: 1000px) {
  .songs {
    grid-template-columns: 1fr 1fr;
  }
}

.songs li {
  justify-self: stretch;
  display: block;
}

.audio-button {
  width: 100%;
  align-items: center;
  padding: 1em;
  display: flex;
  position: relative;
}

.audio-button label {
  margin: 0;
  font-size: 1.1em;
}

.audio-button:before {
  content: "";
  width: 1.5em;
  height: 1.5em;
  background: url("play-icon.69271518.svg") 0 0 / contain no-repeat;
  margin-right: .75em;
  position: relative;
}

.audio-button:disabled {
  opacity: .5;
}

.audio-button.playing {
  background: var(--color-black);
}

.audio-button.playing label {
  color: #fff !important;
}

.audio-button.playing:before {
  background-image: url("stop-icon.b3bfb8f2.svg");
}

section#downloads a {
  text-decoration: none;
}

.download-button {
  background: var(--color-primary);
  box-shadow: 0 .3rem var(--color-black);
  border: none;
  margin: 1rem auto;
  display: block;
}

.download-button label {
  margin: .6em;
  display: block;
}

section#contact a {
  color: #fff;
}

section#contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

section#contact .content {
  gap: 1em;
  display: grid;
}

@media screen and (min-width: 800px) {
  .songs, section#contact .content {
    grid-template-columns: 1fr 1fr;
  }
}

/*# sourceMappingURL=index.edb107a6.css.map */
